<template>
  <div>
      <v-card>
          <v-card-title>Black List</v-card-title>
          <BlacklistList></BlacklistList>
      </v-card>
  </div>
</template>

<script>
import BlacklistList from '@/components/Blacklist/BlacklistList';

export default {
  components: {
    BlacklistList
  }
}

</script>