<template>
  <v-dialog v-model="showingBlacklistFormModal" width="700" scrollable persistent minHeight="1200">
    <v-form ref="blacklistForm" @submit.prevent="createBlackNumber">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{editBlacklistItem ? 'Editar contacto' : 'Crear contacto'}}
        </v-card-title>

        <v-card-text style="max-height: 500px">
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field
              ref="name"
              v-model="form_name"
              :rules="[v => !!v || 'Nombre requerido']"
              outlined
              dense
              autocomplete="off"
              hide-details
              label="Nombre del contacto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" md="3">
              <v-select
              id="selectorflags"
              name="selectorflags"
              v-model="selectorFlags"
              :items="items"
              label="Cod. Area"
              :rules="[v => !!v || 'Caracteristica requerida']"
              required
              hide-details="auto"
              outlined
              dense
              @change="changePrefix"
              ></v-select>
            </v-col>

            <v-col cols="9" md="9">
              <v-text-field
              v-model="form_phone"
              type="number"
              outlined
              :rules="[v => !!v || 'El número es necesario', v => (v && v.length > 7) || 'Mínimo 8 números']"
              dense
              hide-details
              placeholder="Número de teléfono"
              label="Número"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="getUserRole === 0">
            <v-col cols="12" md="12" class="pt-0 pb-0">
              <div class="d-flex align-center" style="height: auto;">
                <v-checkbox
                v-model="form_all_companies"
                class="px-3 mt-4 mb-2"
                hide-details
                ></v-checkbox>
                <label class="pt-3 custom-labels"> Seleccionar todas las empresas</label>
              </div>

              <v-autocomplete v-if="!form_all_companies"
              v-model="form_companies"
              :items="companiesData"
              :filter="searchSelector"
              label="Seleccione una o varias empresas"
              outlined
              dense
              multiple
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                  label
                  >
                    <span class="pr-2">
                        {{ item.name }}
                    </span>
                    <v-icon
                        @click="parent.selectItem(item)"
                        small
                    >
                        $delete
                    </v-icon>
                    </v-chip>
                </template>
              <template v-slot:item="{ item }">
                  <v-chip
                  label
                  >
                  {{ item.name }}
                  </v-chip>
              </template>
              </v-autocomplete>

              <div v-if="form_all_companies">
                <div class="mt-2 mx-1"> <small> Se seleccionan todas las empresas, si quiere excluir alguna del total utilice el siguiente buscador:</small> </div>
                <v-autocomplete class="mt-2" style="max-width: 80%;"
                v-model="excluded_list"
                :prepend-inner-icon="icons.mdiCloseOctagonOutline"
                :items="companiesData"
                :filter="searchSelector"
                :rules="!form_all_companies ? [v => !!v || 'Campo requerido'] : []"
                label="Buscar empresas..."
                outlined
                dense
                multiple
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    >
                      <span class="pr-2">
                          {{ item.name }}
                      </span>
                      <v-icon
                          @click="parent.selectItem(item)"
                          small
                      >
                          $delete
                      </v-icon>
                      </v-chip>
                  </template>
                <template v-slot:item="{ item }">
                    <v-chip
                    label
                    >
                    {{ item.name }}
                    </v-chip>
                </template>
                </v-autocomplete>
              </div>

            </v-col>
            <v-col cols="12" md="12" >
              <v-textarea
                :prepend-inner-icon="icons.mdiMessageBulleted"
                ref="blacklist_description_ref"
                v-model="form_description"
                counter="500"
                maxlength="500"
                placeholder="Descripción"
                id="textarea-input-blacklist"
                outlined
                dense
                hint=""
                hide-details
                label="Descripción"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="closeModal()">
            CANCELAR
          </v-btn>

          <v-btn color="primary" type="submit">
            {{editBlacklistItem ? 'EDITAR CONTACTO' :'CREAR CONTACTO' }}
          </v-btn>
        </v-card-actions>
        <alert-pop-up ref="alert_pop_up" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  mdiMessageBulleted,
  mdiCloseOctagonOutline
} from '@mdi/js'
import AlertPopUp  from '@/components/AlertPopUp.vue';

export default {
  data: () => {
    return {
      form_companies: null,
      form_name: '',
      form_all_companies: false,
      form_phone: '',
      form_tags: null,
      form_description: '',
      form_prefix: 549,
      excluded_list: [],
      selectorFlags: "🇦🇷 (+549)",
      companiesData: [],
      tagsData: [],
      showingBlacklistFormModal: false,
      editBlacklistItem: null,
    }
  },
  components: {
    AlertPopUp
  },
  methods: {
    async getCompanies() {
      const companies = await this.$api.getCompaniesList()
      this.companiesData = companies
    },

    async getTagsData() {
      let form = {
        page: 1,
        page_size: 50
      }
      const response = await this.$api.getTags(form)
      this.tagsData = response.results
    },

    openModal(id_to_edit) {
      this.form_prefix = 549
      this.selectorFlags = "🇦🇷 (+549)"
      this.getCompanies()
      this.getTagsData()

      id_to_edit
              ? this.setItemToEdit(id_to_edit)
              : this.showingBlacklistFormModal = true
    },

    searchSelector (item, queryText, itemText) {
      const hasValue = val => val != null ? val : ''

      const text = hasValue(item.name)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },

    changePrefix(e) {
      const matches = e.match(/\d+/g);

      if (matches) this.form_prefix = Number(matches[0])
    },

    closeModal() {
      this.resetForm()
      this.editBlacklistItem = null
      this.showingBlacklistFormModal = false
      this.form_all_companies = false
    },

    resetForm(){
      this.$refs.blacklistForm.reset()
      this.form_companies = null
      this.form_tags = null
    },

    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getBlackNumber(id_to_edit)
      if(item) {
        this.editBlacklistItem = item[0]

        this.form_name = item[0].name
        this.form_phone = item[0].phone
        this.form_description = item[0].description
        this.form_prefix = parseInt(item[0].prefix.slice(1))
        this.items.forEach(el => {if(el.includes(item[0].prefix)){this.selectorFlags = el}})

        const tagsIds = item[0].tags.map(el => el.id)
        this.form_tags = this.tagsData.filter(el => tagsIds.includes(el.id))

        if(item[0].all_companies) {
          this.form_all_companies = true
        }

        if(Array.isArray(item[0].company)) {
          const companyIds = item[0].company.map(el => el.id)
          this.form_companies = this.companiesData.filter(el => companyIds.includes(el.id))
        } else {
          this.form_companies = item[0].company
        }
      }
      this.showingBlacklistFormModal = true
      this.$forceUpdate()
    },

    async createBlackNumber() {
      if(this.$refs.blacklistForm.validate()) {
        const form = {
          name: this.form_name,
          description: this.form_description,
          prefix: `+${this.form_prefix}`,
          phone: this.form_phone,
          company: this.form_companies ? this.form_companies.map(el => el.id) : [this.getSelectedCompany],
          tags: this.form_tags ? this.form_tags.map(el => el.id) : [],
          all_companies: this.form_all_companies
        }

        if(this.form_all_companies) {
          form.company = []
          form.all_companies = true
          if(this.excluded_list.length){
            form.excluded_list = this.excluded_list ? this.excluded_list.map(el => el.id) : []
          }
        }

        try {
          const response = this.editBlacklistItem
                              ? await this.$api.updateBlackNumber(this.editBlacklistItem.id ,form)
                              : await this.$api.createBlackNumber(form)

          this.$emit('reload-blacklist-list')
          this.closeModal()
        } catch(e) {
          console.log(e)
          if(e?.response?.status === 403) {
            this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data?.message, type: 'error' })
          }
          if(e?.response?.status === 400) {
            console.log(e.response);
            this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data?.error, type: 'error' })
          }
        }
      }
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },
  setup() {
    const items = [
      "🇦🇷 (+549)",
      "🇧🇷 (+55)",
      "🇧🇴 (+591)",
      "🇨🇱 (+56)",
      "🇨🇴 (+57)",
      "🇪🇨 (+593)",
      "🇲🇽 (+52)",
      "🇵🇪 (+51) ",
      "🇵🇾 (+595)",
      "🇺🇾 (+598)",
      "🇻🇪 (+58)",
    ]

    return {
      items,
      icons: {
        mdiMessageBulleted,
        mdiCloseOctagonOutline
      }
    }
  }
}
</script>

<style scoped>

.custom-labels{
  font-size: 16px;
  min-height: 8px;
}

</style>
