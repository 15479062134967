<template>
  <div>
  <!-- list filters -->
  <v-card>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        @keyup="handleSearch"
        placeholder="Buscar por nombre o número de teléfono"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
      >
      </v-text-field>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="$refs.blacklist_form_modal.openModal()"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Agregar contacto a Black list</span>
        </v-btn>

      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="tableColumns"
      :page="current_page"
      :items="blacklistListTable"
      :server-items-length="totalBlacklistListTable"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @update:page="changingBlacklistPage($event)"
      @update:items-per-page="changingBlacklistItemsPerPage($event)"
    >
      <!-- show-select -->


      <!-- no-data -->
      <template  v-slot:no-data>
        No se encontraron números en la lista negra.
      </template>

      <!-- name -->
      <template #[`item.name`]="{item}">
        <span class="font-weight-bold">{{ item.name }}</span>
      </template>

      <!-- company -->
      <template #[`item.company`]="{item}">
        <div v-if="item.company && !item.all_companies" class="d-flex flex-column align-center" style="max-height: 70px; overflow-y: auto">
          <div class="d-flex flex-wrap">
            <v-chip
              v-for="(company, index) in item.company"
              :key="index"
              x-small
              class="primary--text mt-1"
              style="margin-right: 8px; margin-bottom: 8px;"
            >
              {{ company.name }}
            </v-chip>
          </div>
        </div>
        <div v-if="item.all_companies">
          <v-chip
            small
            class="accent--text mt-1"
            style="margin-right: 8px; margin-bottom: 8px;"
            color="accent"
            outlined
          >
            {{ 'Todas las empresas' }}
          </v-chip>
        </div>
        <div v-if="item.company && !item.company.length">-</div>
      </template>

      <template #[`item.description`]="{item}">
          <span
          style="max-width: 200px;"
          class="d-inline-block text-truncate description-span"
          @click="showFullDescription(item.description)"
          >
            {{ item.description }}
          </span>
        </template>

      <!-- edit -->
      <template #[`item.edit`]="{item}">
        <v-btn
          icon
          color="primary"
          @click="$refs.blacklist_form_modal.openModal(item.id)"
        >
          <v-icon>
            {{ icons.mdiSquareEditOutline }}
          </v-icon>
        </v-btn>
      </template>

      <!-- delete -->
      <template #[`item.delete`]="{item}">
        <v-btn icon @click="$refs.delete_blacklist_modal.open(item)">
          <v-icon class="error--text">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <template>
      <v-dialog v-model="showingFullDescriptionModal" max-width="500">
        <v-card class="pa-3">
          <v-card-title class="d-flex justify-center">
              Descripción completa
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              {{ fullDescription }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showingFullDescriptionModal = false">
            Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <blacklist-form-modal
      ref="blacklist_form_modal"
      @reload-blacklist-list="getBlacklist()"
    />

    <delete-blacklist-modal
      ref="delete_blacklist_modal"
      @reload-blacklist-list="getBlacklist()"
    />


  </v-card>
  </div>
</template>

<script>
const debounce = require('debounce');
import {ref} from '@vue/composition-api';
import BlacklistFormModal from '@/components/Blacklist/BlacklistFormModal'
import DeleteBlacklistModal from '@/components/Blacklist/DeleteBlacklistModal'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiSquareEditOutline,
} from '@mdi/js'

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      companiesList: [],
      fullDescription: '',
      showingFullDescriptionModal: false
    }
  },
  components: {
    BlacklistFormModal,
    DeleteBlacklistModal,
  },
  methods: {
    async getBlacklist() {
      this.loading = true
      try {
        let form = {
          page: this.current_page,
          page_size: this.page_size,
          company_id: this.getSelectedCompany,
          search: this.searchQuery,
          company_list: this.getSelectedCompany
        }

        if(this.getSelectedCompany === 'all') {
          delete form.company_id
        }

        const response = await this.$api.getBlacklist(form)
        this.blacklistListTable = response.results
        this.totalBlacklistListTable = response.count
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changingBlacklistPage(e) {
      this.current_page = e
      this.getBlacklist()
    },

    changingBlacklistItemsPerPage(e) {
      this.page_size = e
      this.getBlacklist()
    },
    handleSearch: debounce(function (e) {
      this.current_page = 1
      this.getBlacklist()
    }, 400),

    showFullDescription(description) {
      this.fullDescription = description
      this.showingFullDescriptionModal = true
    },
  },
  mounted() {
    if(this.getSelectedCompany) {
      this.getBlacklist()
    }
  },
  computed: {
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  watch: {
    getSelectedCompany(newValue) {
      if(newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getBlacklist()
      }
    }
  },
  setup() {
    const blacklistListTable = ref([])
    const totalBlacklistListTable = ref(0)
    const loading = ref(false)

    const searchQuery = ref('')

    const tableColumns = [
      { text: 'Nombre', value: 'name', align: 'center', sortable: false },
      { text: 'Prefijo', value: 'prefix', align: 'center', sortable: false },
      { text: 'Teléfono', value: 'phone', align: 'center', sortable: false },
      { text: 'Empresas', value: 'company', align: 'center', sortable: false, width: '420px' },
      { text: 'Descripción', value: 'description', align: 'center', sortable: false },
      { text: 'Editar', value: 'edit', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
    ];

    return {
      blacklistListTable,
      totalBlacklistListTable,
      loading,
      searchQuery,
      tableColumns,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiSquareEditOutline,
      }
    }
  }
}
</script>

<style scoped>
.description-span {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
}

.text-span:hover {
  background-color: rgba(185, 185, 185, 0.3);
}

/* Custom scrollbar styles for Chrome/Opera */
::-webkit-scrollbar {
  width: 5px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-track:hover {
  background-color: #ddd;
}
</style>
