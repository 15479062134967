import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre o número de teléfono","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.blacklist_form_modal.openModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Agregar contacto a Black list")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"page":_vm.current_page,"items":_vm.blacklistListTable,"server-items-length":_vm.totalBlacklistListTable,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:page":function($event){return _vm.changingBlacklistPage($event)},"update:items-per-page":function($event){return _vm.changingBlacklistItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No se encontraron números en la lista negra. ")]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.company && !item.all_companies)?_c('div',{staticClass:"d-flex flex-column align-center",staticStyle:{"max-height":"70px","overflow-y":"auto"}},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((item.company),function(company,index){return _c(VChip,{key:index,staticClass:"primary--text mt-1",staticStyle:{"margin-right":"8px","margin-bottom":"8px"},attrs:{"x-small":""}},[_vm._v(" "+_vm._s(company.name)+" ")])}),1)]):_vm._e(),(item.all_companies)?_c('div',[_c(VChip,{staticClass:"accent--text mt-1",staticStyle:{"margin-right":"8px","margin-bottom":"8px"},attrs:{"small":"","color":"accent","outlined":""}},[_vm._v(" "+_vm._s('Todas las empresas')+" ")])],1):_vm._e(),(item.company && !item.company.length)?_c('div',[_vm._v("-")]):_vm._e()]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate description-span",staticStyle:{"max-width":"200px"},on:{"click":function($event){return _vm.showFullDescription(item.description)}}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$refs.blacklist_form_modal.openModal(item.id)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.delete_blacklist_modal.open(item)}}},[_c(VIcon,{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)}),[_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.showingFullDescriptionModal),callback:function ($$v) {_vm.showingFullDescriptionModal=$$v},expression:"showingFullDescriptionModal"}},[_c(VCard,{staticClass:"pa-3"},[_c(VCardTitle,{staticClass:"d-flex justify-center"},[_vm._v(" Descripción completa ")]),_c(VDivider),_c(VCardText,[_vm._v(" "+_vm._s(_vm.fullDescription)+" ")]),_c(VDivider),_c(VCardActions,{staticClass:"pb-0"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showingFullDescriptionModal = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],_c('blacklist-form-modal',{ref:"blacklist_form_modal",on:{"reload-blacklist-list":function($event){return _vm.getBlacklist()}}}),_c('delete-blacklist-modal',{ref:"delete_blacklist_modal",on:{"reload-blacklist-list":function($event){return _vm.getBlacklist()}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }